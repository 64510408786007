<template>
  <div class="page">
    <header class="header">{{shop.shop_name}}</header>
    <div class="main index">

      <div class="list2">
        <div class="item" v-if="shop.tel">
          <div class="title"><img src="/tel.png" alt="" /></div>
          <div class="after" @click="call(shop.tel)">{{ shop.tel }}</div>
        </div>
        <div class="item" v-if="shop.email">
          <div class="title"><img src="/email.png" alt="" /></div>
          <div class="after">{{ shop.email }}</div>
        </div>
        <div class="item" v-if="shop.addr">
          <div class="title"><img src="/addr.png" alt="" /></div>
          <div class="after">{{ shop.addr }}</div>
        </div>
        <div class="item" v-if="shop.wechat">
          <div class="title"><img src="/wechat2.png" alt="" /></div>
          <div class="after">{{ shop.wechat }}</div>
        </div>
      </div>

      <div class="qr" v-if="shop.wechat_qr">
        <img :src="shop.wechat_qr" alt="">
      </div>

      <div class="content" v-html="shop.content"></div>
    </div>
  </div>
</template>

<style lang='scss' scord>
.qr{
  display: block;
  width: 120px;
  height: 120px;
  margin: 20px auto;
}
.list2{
  background: #fff;
  margin: 20px;
  border-radius: 5px;
}
</style>

<script>
export default {
  data() {
    return {
      shop: {},
    };
  },

  async created() {
    const shop_id = this.$route.params.shop_id;
    const r = await this.$axios.get("/shop/info?shop_id=" + shop_id);
    console.log(r);

    if (r.status == 1) {
      this.shop = r.shop;
    }
  },

  methods: {
    call (no) {
      window.location.href = 'tel:' + no;
    }
  },
};
</script>